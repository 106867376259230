body {
  margin: 0;
  font-family: var(--body-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100vw;
}

body, html {
  max-width: 100vw;
  overflow-x: hidden;
}

:root {
  --primary-color: rgb(253, 253, 253);
  --accent-color: rgb(251, 241, 228);
  
  --header-font: 'Josefin Slab', serif;
  --body-font: "Eczar", serif;
  --ornamental-font: "Arvo", serif
}

@media (min-width:1051px) {
  .block {
    padding: 40px 140px;
  }
}

@media (max-width:1050px) {
  .block {
    padding: 40px 40px;
  }
  #repertoire button {
    margin-left: 40px !important;
  }
}

@media (max-width:700px) and (min-width:601px) {
  #sophie {
    width: 60% !important;
    margin: 30px 100px 100px !important;
  }
}

@media (max-width:600px) {
  #sophie {
    width: 90% !important;
    margin: 20px 80px 50px 10px !important;
  }
}

@media (max-width:1200px) {
  #lessons-block {
    width: 100% !important;
  }
}

@media (max-width:800px) and (min-width:401px) {
  #bug-photo{
    margin-top: 30px !important;
    width: 80% !important;
  }
}

@media (max-width:400px) {
  #bug-photo{
    margin-top: 30px !important;
    width: 140% !important;
    position: relative;
    left: -50px;
  }
}

@media (max-width:1100px) { 
  #repertoire-list {
  padding: 0px 15% 0px !important;
  margin: 20px auto 0px auto;
  width: 70% !important;
  }
}

@media (max-width:750px) and (min-width: 601px) {
  #repertoire-list {
    padding: 0px 15% 0px !important;
    width: 70% !important;
  }
}

@media (max-width:600px) {
  #repertoire-list {
    padding: 0px 5% 0px !important;
    width: 90% !important;
    font-size: .8rem;
  }
}

@media (max-width:600px) {
  #contact form{
    grid-template-columns: 1fr !important;
    grid-template-rows: 96px 125px 3fr 71px !important;
    grid-template-areas:
      "name"
      "email"
      "message"
      "submit" !important;
  }

  #contact input{
    width: 70vw !important;
    height: 30px !important;
  }
}