#lessons {
  background-color: var(--accent-color);
	text-align: justify;
}

#lessons button {
	margin: 0px;
}

#lessons button {
  background: none;
	color: rgb(90, 90, 207);
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
  font-weight: bold;
}

#lessons button:hover {
  color: rgb(54, 54, 167);
}

#bug-photo {
	filter: brightness(115%) contrast(115%) drop-shadow(10px 10px 10px black);
	-webkit-filter: brightness(115%) contrast(115%) drop-shadow(10px 10px 10px black);;
	border: 10px solid white;
	transform: rotate(-8deg);
	display: block;
	margin: 10px auto 3rem;
	width: 60%;
}