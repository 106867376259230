@media (max-width: 600px) {

  .hamburger {
    position: absolute !important;
    right: 30px;
    top: 30px;
    z-index: 10;
  }


  #header {
    background-position: bottom;
  }

  #logo-box {
    height: 200px;
    width: 200px;
  }
    
  #sig {
    height: 167px;
  }

  nav {
    display: flex;
    flex-direction: column;
    width: 40%;
    background-color: rgb(251, 241, 228);
    position: absolute;
    padding: 35vw 5% 60vh;
    z-index: 2;
    right: -50%;
  }

  nav.visible {
    animation: showMenu .3s 0s forwards;
  }

  nav.hidden {
    animation: hideMenu .3s 0s forwards;
  }

  @keyframes showMenu {
    to {right: 0px;}
  }

  @keyframes hideMenu {
    from {right: 0px;}
    to {right: -50%;}
  }

  #header nav button {
    background-color: rgb(255,255,255,.0);
  }
}
