ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

#repertoire button {
color: rgb(90, 90, 207);
padding: 0;
cursor: pointer;
margin-left: 140px;
font-family: var(--body-font);
font-weight: bold;
font-size: 1rem;
}

#repertoire button:hover {
  color: rgb(54, 54, 167);
}

#repertoire button:active {
  background-color: none;
}

#and-more {
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
  font-style: italic;
  font-size: 1.2rem;
}

.composer {
  float: right;
}

.song {
  font-style: italic;
}

#repertoire-list {
  padding: 0px 30% 0px;
  margin: 20px auto 0px auto;
  background-color: rgb(184, 218, 255);
  width: 40%;
  max-height: 0%;
  opacity: 0;
  overflow: hidden;
}

#repertoire-list h2{
  padding-top: 3rem;
}

#repertoire-list.visible {
  max-height: 100%;
  animation: showRepertoire 2s 0s forwards;
}

#repertoire-list.hidden {
  max-height: 0%;
  opacity: 0;
  overflow: hidden;
  animation: hideRepertoire 1s 0s forwards;
}

@keyframes showRepertoire {
  from {opacity: 1; max-height: 0px;}
  to {opacity: 1; max-height: 2000px;}
}

@keyframes hideRepertoire {
  from {opacity: 1; max-height: 2000px;}
  to {opacity: 1; max-height: 0px;}
}

