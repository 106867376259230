h1 {
  font-family: var(--header-font);
  text-align: center;
  font-size: 2rem;
  padding-bottom: 20px;
}

button {
  background-color: white;
  border: none;
  color: black;
  padding: 10px 25px;
  margin: 0px 4px;
  text-align: center;
  text-decoration: none;
  font-size: 1.3rem;
  font-family: var(--ornamental-font);
  cursor: pointer;
}

#copyright {
  text-align: center;
  font-size: .8rem;
  font-family: var(--ornamental-font);
}

.scroll-button {
  position: fixed;
  right: 25px;
  bottom: 30px;
  background-color: cornflowerblue;
  border-radius: 30px;
  padding: 10px 4px;
  z-index: 4;
}

@media (min-width: 1400px) {
  body {
    font-size: 1.2rem;
  }

  button {
    font-size: 1.5rem;
  }

  #repertoire button {
    font-size: 1.5rem !important;
  }
} 