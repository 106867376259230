@media (min-width: 601px) {

nav {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  mix-blend-mode: screen;
  text-align: center;
  width: 100%;
}

}