#about {
  background-color: var(--primary-color);
  text-align: justify;
  position: relative;
  z-index: 3;
}

#upcase-letter {
  float: left;
  line-height: 85%;
  width: .7em;
  font-size: 400%;
}

#sophie {
  float: left;
  margin: 20px 90px 80px 10px;
  filter: brightness(115%) contrast(115%) drop-shadow(10px 10px 10px black);
  -webkit-filter: brightness(115%) contrast(115%) drop-shadow(10px 10px 10px black);
  border: 10px solid white;
  transform: rotate(3deg);
  width: 40%;
}

#about button {
  background: none;
	color: rgb(90, 90, 207);
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
  font-weight: bold;
}

#about button:hover {
  color: rgb(54, 54, 167);
}

#show-more-span{
  display: flex;
  max-height: 0%;
  opacity: 0;
  overflow: hidden;
}

#show-more-span.collapsed {
  max-height: 0%;
  opacity: 0;
  overflow: hidden; 
  animation: hideMore 1s 0s forwards;
}

#show-more-span.expanded {
  max-height: 100%;
  animation: showMore 1s 0s forwards;
}

@keyframes showMore {
  from {opacity: 1; max-height: 0px;}
  to {opacity: 1; max-height: 1000px;}
}

@keyframes hideMore {
  from {opacity: 1; max-height: 1000px;}
  to {opacity: 1; max-height: 0px;}
}